.home {
    &__download-link {
        display: inline-block;
        height: 10rem;
        width: 10rem;
    }
    &__download-badge {
        width: 10rem;
        &--android {
            width: 11rem;
        }

        &--ios {
            margin-top: .75rem;
        }
    }
}