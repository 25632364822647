@import '../../styles/styles.scss';

.navbar {
    background-color: $main-color;
    color: $white;

    &__nav-items {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 0 1rem;
    }

    &__logo {
        width: 50px;
        height: 50px;
    }

    &__nav-item {
        width: 5rem;
        display: flex;
        justify-content: center;
    }

    &__nav-link {
        display: flex;
        align-items: center;
        color: $white;
        font-weight: bold;
        text-decoration: none;
    }

    &__nav-link-text {
        margin: 0 0.5rem;
    }
}