$main-color: #264653;
$secondary-color: #2A9D8F;
$color-orange: #E76F51;
$success-color: #42ac46;
$error-color: #e61616;
$white: #fff;
$bg-color: #f5f5f5;

$tablet-width: 768px;
$tablet-width--wide: 1024px;
$mobile-width: 450px;

// Box shadows for cards.
$box-shadow--normal: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$box-shadow--hover: 0 4px 8px 0 rgba(11, 53, 53, 0.721), 0 6px 20px 0 rgba(0, 0, 0, 0.19);